/* ============================================
   カレンダー
============================================ */
ENGINE.calendar = {
  init: function init() {
    //html デコード処理
    function decodeEntities(encodedString) {
      var textArea = document.createElement('textarea');
      textArea.innerHTML = encodedString;
      return textArea.value;
    }

    //PHPからイベント情報を取得
    if (typeof eventdate !== 'undefined') {
      var events = JSON.parse(eventdate);
      $.each(events, function (i, value) {
        var day = new String(value.date);
        calObj[0].daysClass[day] = decodeEntities(value.html);
      });
    }

    
      //コーディングで処理を確かめる場合は、リテラルで値をセット
      calObj[0].daysClass["2020/11/2"] = 'Birthday;<label class="m_calendar__checkbox"><input type="checkbox" id="event_01" name="event[]" value="2020/4/2"><span></span></label><p class="m_calendar__plan--items"><span>6</span><small>件</small></p><ul class="m_calendar__plan--list"><li>水島</li><li class="is_active">小澤<p>AM</p></li><li>佐山<p>PM</p></li><li>水島</li></ul>';
      calObj[0].daysClass["2020/11/4"] = 'Birthday;<label class="m_calendar__checkbox"><input type="checkbox" id="event_02" name="event[]" value="2020/4/4"><span></span></label><p class="m_calendar__plan--items"><span>12</span><small>件</small></p><ul class="m_calendar__plan--list"><li>水島</li><li>小澤</li><li>佐山</li><li>水島</li></ul>';
      calObj[0].daysClass["202011/5"] = 'Birthday;<label class="m_calendar__checkbox"><input type="checkbox" id="event_03" name="event[]" value="2020/4/5"><span></span></label><p class="m_calendar__plan--items"><span>12</span><small>件</small></p><ul class="m_calendar__plan--list"><li>水島</li><li>小澤</li><li>佐山</li><li>水島</li></ul>';
      calObj[0].daysClass["2020/11/7"] = 'Birthday;<label class="m_calendar__checkbox"><input type="checkbox" id="event_04" name="event[]" value="2020/4/7"><span></span></label><p class="m_calendar__plan--items"><span>12</span><small>件</small></p><ul class="m_calendar__plan--list"><li>水島</li><li>小澤</li><li>佐山</li><li>水島</li></ul>';
      calObj[0].daysClass["2020/11/10"] = 'Birthday;<label class="m_calendar__checkbox"><input type="checkbox" id="event_05" name="event[]" value="2020/4/10"><span></span></label><p class="m_calendar__plan--items"><span>5</span><small>件</small></p><ul class="m_calendar__plan--list"><li>水島</li><li>小澤</li><li>佐山</li><li>水島</li></ul>';
      calObj[0].daysClass["2020/11/13"] = 'Birthday;<label class="m_calendar__checkbox"><input type="checkbox" id="event_06" name="event[]" value="2020/4/13"><span></span></label><p class="m_calendar__plan--items"><span>12</span><small>件</small></p><ul class="m_calendar__plan--list"><li>水島</li><li>小澤</li><li>佐山</li><li>水島</li></ul>'; // [0]のカレンダーを[1]にコピー
    
    
    calObj[1] = new Object();
    calObj[1] = cal_clone(calObj[0]);
    calObj[1].defaultMonth = 0; //初期表示 月
    calObj[1].weekStart = 0;

    //SP用のサイドバーカレンダー
    calObj[2] = new Object();
    calObj[2] = cal_clone(calObj[0]);
    calObj[2].click = true;
    calObj[2].clickURI = '/seminar/result/?event[]=_YEAR_/_MONTH_/_DAY_';
    calObj[2].defaultMonth = 0; //初期表示 月
    calObj[2].weekStart = 0;
    cal_init();
    ENGINE.calendar.ready();
  },
  
  ready: function ready() {
    function holiday() {
      /* 日曜日 */
      if ($('*').hasClass('js_sun')) {
        $('.js_sun').parents('td').addClass('is_sun');
      }
      /* 土曜日 */
      if ($('*').hasClass('js_sat')) {
        $('.js_sat').parents('td').addClass('is_sat');
      }
      /* 祝祭日 */
      if ($('*').hasClass('js_holiday')) {
        $('.js_holiday').parents('td').addClass('is_holiday');
      }
    }
    holiday();
  }
}